import React, { FC } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  PageTitle,
  TitleBorder,
  VerticalCard,
  VerticalCardBody
} from '@bscs-dev-team/bscs-design-system-core'

import Layout from '../../components/layout/layout'
import SEO from '../../components/seo'


type Frontmatter = {
  additionalTags: string[],
  alt: string,
  areas: string[],
  cardDescription: string,
  date: string,
  discipline: string,
  gradeLevel: number,
  image: unknown,
  programLength: string,
  sortOrder: number,
  title: string,
  page: string
}

type Node = {
  id: string,
  excerpt: string
  frontmatter: Frontmatter
}

type Edge = {
  node: Node
}

type AllMdx = {
  edges: Edge[]
}

type Data = {
  allMdx: AllMdx
}

type ITIProps = {
  data: Data
}

const InvitationsToInquiryPage: FC<ITIProps> = ({ data }: ITIProps) => {
  const resources: Edge[] = data.allMdx.edges

  return (
    <React.Fragment>
      <SEO
        title="Science Teachers Invitations to Inquiry Resources"
        description="BSCS Science Learning is an independent nonprofit offering science teachers Invitations to Inquiry resources across the US."
        canonicalUrl="https://bscs.org/resources/invitations-to-inquiry/"
      />
      <Layout>
        <section className="section">
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link className='inline-anchor' to='/'>Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                Resources
              </BreadcrumbItem>
              <BreadcrumbItem>
                Invitations to Inquiry
              </BreadcrumbItem>
            </Breadcrumb>
            <PageTitle>Invitations to Inquiry with Data using FieldScope</PageTitle>
            <TitleBorder />
            <div>
              <div>
                <p>
                  Invitations to Inquiry are short instructional activities designed to help middle and high school students work with large data sets. The activities engage students with community and citizen science data from projects hosted on FieldScope. Teachers and students use the interactive FieldScope platform to collect, visualize, and analyze environmental data. With these new Inquiries, students can explore FieldScope’s advanced mapping and graphing tools to dig deeper into data in the context of meaningful science classroom lessons.
                </p>
                <p>
                  Each lesson engages students in interpreting graphs or maps, or both, and figuring out what the data means. Students will use data to answer questions like “What is the best time of year to host a lilac blossom festival?” Ultimately, the Inquiries are intended to increase student confidence in working with data and using visualization tools as part of the process.
                </p>
                <p>
                  These Inquiries are designed for 2-4 days of learning and support the <a href="https://ngss.nsta.org/PracticesFull.aspx" target="_blank" rel="noopener noreferrer">Science and Engineering Practices&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon" /></sup></a> from the Next Generation Science Standards. They include teacher guides, slides, handouts, and other instructional resources and supports. The activities work best with at least one computer for every 2-3 students along with high speed internet access.
                </p>
                <p>
                  To learn more about the design of the Invitations to Inquiry, see this <a href="https://drive.google.com/file/d/1P_m3oI3l0_k5Na96ThNwM5ssZFC7R5x1/view" target="_blank" rel="noopener noreferrer">overview&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon" /></sup></a>.
                </p>
              </div>
            </div>
            <hr />
          </div>
        </section>
        <section>
          <div className="flex flex-wrap mt-5 w-full">
            {
              resources.map((edge, index) => {
                let dataFilter = JSON.parse(JSON.stringify(edge.node.frontmatter))
                dataFilter['excerpt'] = edge.node.excerpt

                return (
                  <div
                    className="flex justify-center mt-4 md:w-1/2 lg:w-1/3 xl:w-1/4 w-full"
                    key={`resource-${index}`}
                  >
                    <VerticalCard
                      className="flex w-full"
                      data-filter={JSON.stringify(dataFilter)}
                      id={`resource-${index}`}
                    >
                      <Img
                        alt={edge.node.frontmatter.alt}
                        className="h-64 rounded-t w-full"
                        fluid={edge.node.frontmatter.image.childImageSharp.fluid}
                      />
                      <VerticalCardBody>
                        <h3>{edge.node.frontmatter.title}</h3>
                        <p>
                          {edge.node.frontmatter.cardDescription}
                          {!edge.node.frontmatter.cardDescription && edge.node.excerpt}
                        </p>
                      </VerticalCardBody>
                      <div className="flex justify-end items-end p-5 w-full">
                        <Link
                          to={`/resources/invitations-to-inquiry/${edge.node.frontmatter.title.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()}`}
                        >
                          <Button variant="indigo">Read More</Button>
                        </Link>
                      </div>
                    </VerticalCard>
                  </div>
                )
              })
            }
            <div className="my-5">
              <div>
                <hr className="mb-5" />
                <p className="text-center"><em>This work is funded by a grant from the <a href="https://piscesfoundation.org/" target="_blank" rel="noopener noreferrer">Pisces Foundation&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon"></i></sup></a>.</em></p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </React.Fragment>
  )
}

export default InvitationsToInquiryPage

export const invitationsToInquiryPageQuery = graphql`
  query invitationsToInquiryPageQuery {
    allMdx(
      filter: {frontmatter: { status: {eq: "Ready"}, page: {eq: "invitations-to-inquiry"}}}
      sort: {
        fields: [frontmatter___sortOrder, frontmatter___title],
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          frontmatter {
            additionalTags,
            alt,
            areas,
            cardDescription,
            date,
            discipline,
            gradeLevel,
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            programLength,
            sortOrder,
            title,
            page
          }
        }
      }
    }
  }
`
